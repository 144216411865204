import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import './Dashboard.css';

Modal.setAppElement('#root');

function formatSize(size) {
  if (size >= 1024 ** 3) return `${(size / 1024 ** 3).toFixed(2)} GB`;
  if (size >= 1024 ** 2) return `${(size / 1024 ** 2).toFixed(2)} MB`;
  if (size >= 1024) return `${(size / 1024).toFixed(2)} KB`;
  return `${size} B`;
}

function Dashboard() {
  const [files, setFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [folderName, setFolderName] = useState('');
  const [currentPath, setCurrentPath] = useState('');
  const [fileProgress, setFileProgress] = useState({});
  const [uploading, setUploading] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [previewModal, setPreviewModal] = useState({ isOpen: false, content: null });
  const [confirmModal, setConfirmModal] = useState({ isOpen: false, action: null });
  const [folderModalOpen, setFolderModalOpen] = useState(false);
  const [contextMenu, setContextMenu] = useState({ isVisible: false, x: 0, y: 0, target: null });

  const fetchFiles = async (path = '') => {
    try {
      const response = await axios.get(
        `https://cloud.irenecleaning.com/api/files?path=${encodeURIComponent(path)}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      );
      const files = response.data.files;

      const sortedFiles = [...files].sort((a, b) => {
        if (a.is_directory && !b.is_directory) return -1;
        if (!a.is_directory && b.is_directory) return 1;
        return a.name.localeCompare(b.name);
      });

      setFiles(sortedFiles);
      setCurrentPath(path);
    } catch (err) {
      alert('Failed to fetch files');
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!selectedFiles || selectedFiles.length === 0) {
      alert('No files selected!');
      return;
    }
  
    const formData = new FormData();
    Array.from(selectedFiles).forEach((file) => formData.append('files', file));
    formData.append('path', currentPath);
  
    setUploading(true);
  
    const uploads = Array.from(selectedFiles).map(async (file) => {
      const individualFormData = new FormData();
      individualFormData.append('file', file);
      individualFormData.append('path', currentPath);
  
      setFileProgress((prev) => ({
        ...prev,
        [file.name]: { progress: 0, status: 'Uploading...' },
      }));
  
      try {
        const startTime = Date.now();
  
        await axios.post('https://cloud.irenecleaning.com/api/upload', individualFormData, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          onUploadProgress: (progressEvent) => {
            const duration = (Date.now() - startTime) / 1000;
            const speed = (progressEvent.loaded / 1024 / duration).toFixed(2);
            const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
  
            setFileProgress((prev) => ({
              ...prev,
              [file.name]: { progress: percentage, speed, status: 'Uploading...' },
            }));
          },
        });
  
        // Cambiar estado a "Processing" cuando la carga esté completa
        setFileProgress((prev) => ({
          ...prev,
          [file.name]: { progress: 100, speed: 0, status: 'Processing on server...' },
        }));
  
        // Simular procesamiento del servidor
        await new Promise((resolve) => setTimeout(resolve, 2000));
  
        // Finalizar el progreso y marcar como "Completed"
        setFileProgress((prev) => ({
          ...prev,
          [file.name]: { progress: 100, speed: 0, status: 'Completed' },
        }));
      } catch (err) {
        // Si falla, marcar como "Failed"
        setFileProgress((prev) => ({
          ...prev,
          [file.name]: { progress: 0, speed: 0, status: 'Failed' },
        }));
      }
    });
  
    // Esperar a que todos los archivos se suban
    await Promise.all(uploads);
  
    // Después de la carga, detener el estado de "uploading" y limpiar las barras de progreso
    setUploading(false);
    setFileProgress({});
    fetchFiles(currentPath); // Actualizar la lista de archivos
  };
  
  const handleCreateFolder = async (e) => {
    e.preventDefault();
    if (!folderName.trim()) {
      alert('Folder name is required!');
      return;
    }

    try {
      await axios.post(
        'https://cloud.irenecleaning.com/api/folder',
        { folder_name: folderName, path: currentPath },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      setModalMessage(`Folder '${folderName}' created successfully!`);
      setIsModalOpen(true);
      setFolderName('');
      setFolderModalOpen(false);
      fetchFiles(currentPath);
    } catch (err) {
      setModalMessage('Failed to create folder!');
      setIsModalOpen(true);
    }
  };

  const confirmDelete = (filename, isDirectory = false) => {
    setConfirmModal({
      isOpen: true,
      action: () => handleDelete(filename, isDirectory),
    });
  };

  const handleDelete = async (filename, isDirectory = false) => {
    try {
      await axios.delete('https://cloud.irenecleaning.com/api/delete', {
        params: { filename, path: currentPath },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setModalMessage(isDirectory ? 'Folder deleted successfully!' : 'File deleted successfully!');
      setIsModalOpen(true);
      fetchFiles(currentPath);
    } catch (err) {
      setModalMessage('Delete failed!');
      setIsModalOpen(true);
    } finally {
      setConfirmModal({ isOpen: false, action: null });
    }
  };

  const downloadFile = (url, name) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = name;
    link.click();
  };

  const openFolder = (folderName) => {
    const newPath = currentPath ? `${currentPath}/${folderName}` : folderName;
    fetchFiles(newPath);
  };

  const goBack = () => {
    const pathParts = currentPath.split('/').filter(Boolean);
    pathParts.pop();
    const newPath = pathParts.join('/');
    fetchFiles(newPath);
  };

  const openPreviewModal = (content) => {
    setPreviewModal({ isOpen: true, content });
  };

  const closePreviewModal = () => {
    setPreviewModal({ isOpen: false, content: null });
  };

  const handleContextMenu = (event, target) => {
    event.preventDefault();
    setContextMenu({
      isVisible: true,
      x: event.pageX,
      y: event.pageY,
      target,
    });
  };

  const closeContextMenu = () => {
    setContextMenu({ isVisible: false, x: 0, y: 0, target: null });
  };

  const getFilePreview = (file) => {
    const fileExtension = file.name.split('.').pop().toLowerCase();
    if (['jpg', 'jpeg', 'png', 'gif', 'ico', 'bmp', 'tiff'].includes(fileExtension)) {
      return (
        <img
          src={file.url}
          alt={file.name}
          className="file-preview"
          onClick={() => openPreviewModal(<img src={file.url} alt={file.name} className="modal-image" />)}
        />
      );
    } else if (['mp4', 'webm', 'ogg', 'mov', 'mkv', 'avi'].includes(fileExtension)) {
      return (
        <div
          className="video-preview-container"
          onClick={() =>
            openPreviewModal(
              <video src={file.url} controls className="modal-video" autoPlay />
            )
          }
        >
          <video src={file.url} className="video-preview" muted playsInline />
          <div className="video-overlay">
            <p>Click to Preview</p>
          </div>
        </div>
      );
    } else if (['mp3', 'wav', 'ogg'].includes(fileExtension)) {
      return <audio src={file.url} controls className="file-preview" />;
    } else {
      return (
        <a href={file.url} target="_blank" rel="noopener noreferrer">
          {file.name}
        </a>
      );
    }
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  return (
    <div className="dashboard-container" onClick={closeContextMenu}>
      <h2 className="dashboard-title">CYBER STORAGE DASHBOARD</h2>

      <div className="path-navigation">
        <button onClick={() => fetchFiles('')} className="cyber-button">🏠 Home</button>
        {currentPath && <button onClick={goBack} className="cyber-button">⬅ Back</button>}
        <span className="current-path">{currentPath || '/'}</span>
      </div>

      <form onSubmit={handleUpload} className="upload-form">
        <input
          type="file"
          onChange={(e) => setSelectedFiles(e.target.files)}
          multiple
          disabled={uploading}
          className="file-input"
        />
        <button type="submit" disabled={uploading} className="cyber-button">
          Upload Files
        </button>
      </form>

      <button className="cyber-button" onClick={() => setFolderModalOpen(true)}>
        Create Folder
      </button>

      {Object.keys(fileProgress).map((fileName) => (
        <div key={fileName} className="progress-container">
          <p className="progress-file-name">{fileName}</p>
          <div className="progress-bar">
            <div
              className="progress-fill"
              style={{ width: `${fileProgress[fileName].progress}%` }}
            ></div>
          </div>
          <p className="progress-text">
            {fileProgress[fileName].status} - {fileProgress[fileName].progress}%
          </p>
          {fileProgress[fileName].speed && (
            <p className="progress-speed">{fileProgress[fileName].speed} KB/s</p>
          )}
        </div>
      ))}

      <div className="folder-list">
        {files.filter((file) => file.is_directory).map((folder, index) => (
          <div
            key={index}
            className="folder-item"
            onContextMenu={(e) => handleContextMenu(e, folder)}
          >
            <button
              className="folder-button styled-card"
              onClick={() => openFolder(folder.name)}
              onMouseEnter={() => setContextMenu({ isVisible: false })}
            >
              📁 {folder.name}
            </button>
          </div>
        ))}
      </div>

      <div className="file-list">
        {files.filter((file) => !file.is_directory).map((file, index) => (
          <div
            key={index}
            className="file-item"
            onContextMenu={(e) => handleContextMenu(e, file)}
          >
            {getFilePreview(file)}
            <div className="file-info">
              <p><strong>Name:</strong> {file.name}</p>
              <p><strong>Size:</strong> {formatSize(file.size)}</p>
              <p><strong>Uploaded:</strong> {new Date(file.uploaded_at).toLocaleString()}</p>
            </div>
          </div>
        ))}
      </div>

      {contextMenu.isVisible && (
        <div
          className="context-menu"
          style={{ top: contextMenu.y, left: contextMenu.x }}
        >
          {contextMenu.target.is_directory ? (
            <button
              onClick={() => confirmDelete(contextMenu.target.name, true)}
              className="context-menu-button"
            >
              Delete Folder
            </button>
          ) : (
            <>
              <button
                onClick={() => confirmDelete(contextMenu.target.name, false)}
                className="context-menu-button"
              >
                Delete File
              </button>
              <button
                onClick={() => downloadFile(contextMenu.target.url, contextMenu.target.name)}
                className="context-menu-button"
              >
                Download
              </button>
            </>
          )}
        </div>
      )}

      <Modal
        isOpen={previewModal.isOpen}
        onRequestClose={closePreviewModal}
        contentLabel="Preview Modal"
        className="preview-modal"
        overlayClassName="preview-overlay"
      >
        {previewModal.content}
        <button onClick={closePreviewModal} className="cyber-button close-modal-button">
          Close
        </button>
      </Modal>

      <Modal
        isOpen={confirmModal.isOpen}
        onRequestClose={() => setConfirmModal({ isOpen: false, action: null })}
        contentLabel="Confirmation Modal"
        className="modal"
        overlayClassName="overlay"
      >
        <h2>Are you sure?</h2>
        <button onClick={confirmModal.action} className="cyber-button">Yes</button>
        <button
          onClick={() => setConfirmModal({ isOpen: false, action: null })}
          className="cyber-button"
        >
          No
        </button>
      </Modal>

      <Modal
        isOpen={folderModalOpen}
        onRequestClose={() => setFolderModalOpen(false)}
        contentLabel="Create Folder"
        className="modal"
        overlayClassName="overlay"
      >
        <h2>Create New Folder</h2>
        <form onSubmit={handleCreateFolder}>
          <input
            type="text"
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
            placeholder="Folder Name"
            className="text-input"
          />
          <button type="submit" className="cyber-button">
            Create
          </button>
          <button
            type="button"
            onClick={() => setFolderModalOpen(false)}
            className="cyber-button"
          >
            Cancel
          </button>
        </form>
      </Modal>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Message Modal"
        className="modal"
        overlayClassName="overlay"
      >
        <p>{modalMessage}</p>
        <button onClick={() => setIsModalOpen(false)} className="cyber-button">
          Close
        </button>
      </Modal>
    </div>
  );
}

export default Dashboard;
